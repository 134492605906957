import Grid from './Grid.jsx';
import NavBar from './NavBar.jsx';
import ImageModal from './ImageModal';

import { useState } from "react";


export default function PlacePage(props) {
    const place = props.place;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalLink, setModalLink] = useState(null);

    // fix bolding later
    // document.getElementsByClassName("navlink-" + place)[0].style.fontWeight = "bold";

    return (
        <div>
            <div>
                {modalOpen && <ImageModal modalLink={modalLink} setModalOpen={setModalOpen}/>}
            </div>
            <div className = "place-page-div">
            {!modalOpen && <div>
                <NavBar />
                <Grid place={place} setModalOpen={setModalOpen} setModalLink={setModalLink}/>
            </div>}
        </div>
        </div>
        
    );
}