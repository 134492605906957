import { useState } from "react";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";

function ImageModal({ modalLink, setModalOpen }) {
  // Hook to manage if image is loaded or not.
  var [imgLoaded, setImgLoaded] = useState(false);

  // Exits modal when background of modal-div is clicked.
  function backgroundClick(event) {
    setModalOpen(false);
  }

  // Prevents exiting of modal when image div is clicked.
  function imageClick(event) {
    event.stopPropagation();
  }

  // Prevents image div from being shown until image is fully loaded.
  var imageLoadedStyle = imgLoaded ? {} : { display: "none" };

  return (
    <div className="modal-div" onClick={backgroundClick}>
      <button
        className="close-modal-button"
        onClick={() => setModalOpen(false)}
      >
        <img
          className="close-modal-icon"
          src="/close-icon.svg"
          alt="Icon to close modal."
        />
      </button>

      <div
        className="modal-image-div"
        onClick={imageClick}
        style={imageLoadedStyle}
      >
        <InnerImageZoom
          src={modalLink}
          hideHint={true}
          imgAttributes={{
            className: "full-image",
            alt: "Large image",
            onLoad: () => setImgLoaded(true),
          }}
        />
      </div>
    </div>
  );
}

export default ImageModal;
