import { useState } from "react";

function Photo({ path, thumbPath, setModalOpen, setModalLink }) {
  var [imgLoaded, setImgLoaded] = useState(false);

  function onClick() {
    setModalOpen(true);
    setModalLink(path);
  }

  /* returns a placeholder div until the image is fully loaded, then serve the image */

  return (
    <div>
      {
        <button className="image-button" onClick={onClick}>
          <img
            src={thumbPath}
            className="thumbnail"
            style={imgLoaded ? {} : { display: "none" }}
            onLoad={() => setImgLoaded(true)}
            alt="photo"
          />
        </button>
      }

      {!imgLoaded && <div className="temp-thumb-div"></div>}
    </div>
  );
}

export default Photo;
