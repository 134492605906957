import { Link } from "react-router-dom";

import NavLink from "./NavLink.jsx";

export default function NavBar() {
  let places = ["Alaska", "Berkeley", "Dubai", "India"];

  let links = places.map((name) => {
    return <NavLink link={"/" + name} place={name} key={"/" + name} />;
  });

  return (
    <div className="navbar-div">
      <Link to="/" className="website-name">
        {" "}
        Anirudh's Photos{" "}
      </Link>
      <div className="links-div">{links}</div>
    </div>
  );
}
