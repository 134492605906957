import NavBar from "./NavBar";

export default function Home() {
  return (
    <div className="home-div">
      <NavBar />

      <div className="home-content-div">
        <div className="intro-text-div">
          <p className="intro-text">Hi. Welcome to my photography site.</p>
          <p className="intro-text">
            Cameras have fascinated me for a long time. For most of the past
            decade, I enjoyed telling stories by creating short films with
            friends. More recently, I've grown interested in capturing single
            moments in time, which I'll share here.
          </p>
          <p className="intro-text">
            I hope that you can find some calm through viewing where our world's
            been and where it's headed.
          </p>
          <p className="intro-text">-Anirudh</p>
        </div>
        <img src="/new-home-photo-min.jpg" className="home-photo" />
      </div>
    </div>
  );
}

// to me, certain peace that bustle of life is nothing more than serene moments
