import logo from "./logo.svg";
import "./App.css";
import Portfolio from "./components/Portfolio.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PlacePage from "./components/PlacePage";
import Home from "./components/Home";

import ReactGA from "react-ga4";

// import { SpeedInsights } from "@vercel/speed-insights/next";
import { Analytics } from "@vercel/analytics/react";

import { useEffect } from "react";

function App() {
  let places = ["Alaska", "Berkeley", "Dubai", "India"];

  let placeRoutes = places.map((place) => {
    return (
      <Route
        path={"/" + place}
        element={<PlacePage place={place} />}
        key={"/" + place}
      />
    );
  });

  var setAnalytics = () => {
    ReactGA.initialize("G-X2HYNX573N");
  };

  // useEffect for doing something after render
  useEffect(() => {
    // general analytics
    setAnalytics();

    // analytics for which pages are viewed
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname,
    });
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {placeRoutes}
      </Routes>
      {/* <SpeedInsights /> */}
      <Analytics />
    </div>
  );
}

export default App;
