import Masonry from "react-masonry-css";
import Photo from "./Photo.jsx";

import images from "../links.js"; // react knows to start in public

export default function Grid({ place, setModalOpen, setModalLink }) {
  var photos = images.map((imageObj) => {
    var path = imageObj.path;
    var thumbPath = imageObj.thumb_path;

    // make sure path is to right place.
    if (path.includes(place)) {
      return (
        <Photo
          path={path}
          thumbPath={thumbPath}
          setModalOpen={setModalOpen}
          setModalLink={setModalLink}
        />
      );
    } else {
      return null;
    }
  });

  // columns based on width of window
  // change these depending on size of the images!
  const breakpointColumnsObj = {
    default: 4,
    1600: 3,
    1200: 2,
    700: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="photo-grid"
      columnClassName="photo-grid-column"
    >
      {photos}
    </Masonry>
  );
}
